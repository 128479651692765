import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Video from "components/Video/Video.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import StoraEnso from "assets/img/logos/storaenso-480x480.png";
import Telia from "assets/img/logos/telia-480x480.png";
import Nokia from "assets/img/logos/nokia-480x480.png";

class Thermal360Section extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Paper Mill Maintenance</h2>
              <h4>Recognizing production line issues via 360° and thermal video</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <Video
                        videoSrcURL="https://www.youtube-nocookie.com/embed/zUr6f8vP0ek"
                        videoTitle="Official Music Video on YouTube"
                        aspectRatio="54%"
                      />
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Everyone is familiar with different qualities of paper. This everyday material is
                          made with enormous machines that require a complete factory building to house one.
                          Their production speed is astonishing: almost two kilometers of paper is made in
                          just one minute!
                        </p>
                        <p>
                          However, there are days when something goes wrong and the machine must be stopped.
                          To minimize any pauses in production, the industry strives to track and remove all
                          causes of production problems. Here modern camera technologies can play an important
                          part: 360° cameras, which can see everywhere around them, and thermal cameras, which
                          reveal what a human eye cannot see, provide valuable insights.
                        </p>
                        <p>
                          Finwe has helped Store Enso with both camera technologies. For example, a live 360°
                          stream, transmitted over 5G network, has been running in long-term at their Oulu
                          factory.
                        </p>
                        <p>
                          <b>
                            To learn how 360° video and thermal cameras can be used in your company, contact
                            us and book an e-meeting. Our offering includes on-site consultation for analysing
                            potential applications in co-operation with the customer.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Customer:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={StoraEnso}
                              alt="StoraEnso"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Partners:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Telia}
                              alt="Telia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Nokia}
                              alt="Nokia"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(Thermal360Section);
